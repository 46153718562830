import { useRouter } from "next/router";

import { routes } from "@/client/routes";
import { withAuthentication } from "@/hooks/useAuth";

const HomePage = () => {
  const router = useRouter();

  router.push(routes.transactions(router.query));

  return null;
};

export default withAuthentication(HomePage);
